.footer-container {
  /* margin-left: 10px; */
  /* position: fixed; */
  background-color: black;
  padding-top: 40px;
  padding-bottom: 40px;
  top: auto;
  bottom: 0;
  justify-content: center;
  text-align: center;
  color: white;
}

.footer-container ul li {
  list-style-type: none;
  text-align: center;
  color: white;
  display: inline-block;
  padding: 0px 10px 0px 0px;
}
