.container {
  padding: 2px 16px;
  margin: 0;
}
.card {
  width: 40%;
  margin: 20px;
  background-color: aqua;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
img {
  width: 100%;
}
.small-block {
  position: absolute;
  background-color: black;
  color: white;
  margin-top: 5px;
  margin-left: 5px;
  padding: 5px;
}
