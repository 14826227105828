@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");

/* h1,
p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
} */
* {
  margin: 0;
  padding: 0;
}
body {
  min-height: 100vh;
  /* font-family: 'Secular One', sans-serif; */
  /* font-family: 'Bree Serif', serif; */
}
h1 {
  text-align: center;
}
