.container {
  margin: 10px;
}
button {
  padding: 2px;
  width: 60px;
  background-color: red;
  border: none;
  border-radius: 3px;
}
